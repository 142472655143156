import { useEffect, useState } from "react";
import styled from "styled-components";

const SelectionProcessSection = styled.section`
  position: relative;
  z-index: 9;
  width: 100%;
  max-width: 845px;
  margin: 100px auto;
  .list {
    width: 100%;
    display: flex;
    position: relative;
    justify-content: space-between;
    margin-top: 46px;
    .line {
      width: calc(100% - 40px);
      position: absolute;
      top: 51px;
      left: 20px;
      height: 1px;
      background-color: #686868;
    }
    .item {
      width: 124px;
      h3 {
        font-family: Neue-Haas, sans-serif;
        font-size: 17px;
        font-weight: 500;
        line-height: 21px;
        text-align: center;
        padding-top: 15px;
        color: #686868;
        transition: all ease 0.2s;
      }
      .hexagon {
        position: relative;
        width: 90px;
        height: 51.96px;
        background-color: #686868;
        margin: 25.98px auto;
        transition: all ease 0.2s;
        .month {
          display: block;
          font-family: Neue-Haas, sans-serif;
          font-size: 10px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: center;
          text-transform: uppercase;
          color: #0c1318;
        }
        .date {
          display: block;
          font-family: Neue-Haas, sans-serif;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: 25px;
          letter-spacing: 0.01em;
          text-align: center;
          color: #0c1318;
        }
        &:before,
        &:after {
          content: "";
          position: absolute;
          width: 0;
          border-left: 45px solid transparent;
          border-right: 45px solid transparent;
          transition: all ease 0.2s;
        }
        &:before {
          bottom: 100%;
          border-bottom: 25.98px solid #686868;
        }
        &:after {
          top: 100%;
          left: 0;
          width: 0;
          border-top: 25.98px solid #686868;
        }
      }
      &.active {
        h3 {
          color: ${({ theme }) => theme.colors.secondary};
        }
        .hexagon {
          background-color: ${({ theme }) => theme.colors.buttonColor};
          &:before {
            border-bottom-color: ${({ theme }) => theme.colors.buttonColor};
          }
          &:after {
            border-top-color: ${({ theme }) => theme.colors.buttonColor};
          }
        }
      }
    }
  }
  @media ${({ theme }) => theme.mediaQueries.bellowPhone} {
    margin-top: 0;
    padding: 0 30px;
    .list {
      flex-direction: column;
      padding-right: 20px;
      .line {
        width: 1px;
        height: calc(100% - 40px);
        top: 5px;
        left: 29px;
      }
      .item {
        width: 100%;
        justify-content: flex-start;
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        h3 {
          font-size: 17px;
          font-weight: 400;
          line-height: 21px;
          letter-spacing: 0em;
          text-align: left;
          margin-left: 20px;
        }
        .hexagon {
          margin: 17.32px 0;
          width: 60px;
          height: 34.64px;
          .month {
            font-size: 9px;
            line-height: 16px;
          }
          .date {
            font-size: 16px;
            line-height: 20px;
          }
          &:before,
          &:after {
            content: "";
            border-left: 30px solid transparent;
            border-right: 30px solid transparent;
          }

          &:before {
            border-bottom-width: 17.32px;
          }

          &:after {
            border-top-width: 17.32px;
          }
        }
      }
    }
  }
`;

const processItems = [
  {
    month: "March",
    date: "25th",
    text: "Application opens",
  },
  {
    month: "April",
    date: "22nd",
    text: "Application closes",
  },
  {
    month: "May",
    date: "15th",
    text: "Finalists announced",
  },
  {
    month: "May",
    date: "24th",
    text: "Fellows selected",
  },
  {
    month: "June",
    date: "17th",
    text: "Fellowship begins!",
  },
];

const SelectionProcess: React.FC = () => {
  const [active, setActive] = useState(0);

  const next = () => {
    setActive((current) => (current + 1 > processItems.length - 1 ? 0 : current + 1));
  };

  useEffect(() => {
    const timeout = setInterval(next, 1200);
    return () => {
      clearInterval(timeout);
    };
  }, []);

  return (
    <SelectionProcessSection>
      <h1 className="standard-title">Selection Process</h1>

      <div className="list">
        <div className="line" />
        {processItems.map((item, index) => (
          <div key={index} className={active === index ? "item active" : "item"}>
            <div className="hexagon">
              <span className="month">{item.month}</span>
              <span className="date">{item.date}</span>
            </div>
            <h3>{item.text}</h3>
          </div>
        ))}
      </div>
    </SelectionProcessSection>
  );
};

export default SelectionProcess;
