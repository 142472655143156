import { APPLICATIONS_OPEN, TYPEFORM_LINK } from "config/Config";
import styled from "styled-components";

const ApplicationClosedSectionWrapper = styled.div`
  width: 100%;
  max-width: 455px;
  margin: 0 auto;
  padding: 150px 25px 100px 25px;
  position: relative;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
  z-index: 1;

  h5 {
    font-family: "new-order", sans-serif;
    font-weight: 600;
    font-size: 30px;
    line-height: 47px;
    text-transform: uppercase;
  }

  span {
    font-family: Neue-Haas, sans-serif;
    font-weight: 400;
    size: 16px;
    line-height: 26px;
    color: #cecfd0;
  }

  @media ${({ theme }) => theme.mediaQueries.bellowTabletPortrait} {
    h5 {
      font-size: 20px;
      line-height: 33px;
    }
  }
`;

const ApplicationClosed: React.FC = () => {
  return (
    <ApplicationClosedSectionWrapper id="application">
      <h5>2024 Applications</h5>
      <span>
        {APPLICATIONS_OPEN ? (
          <>
            2024 Applications are now available. Apply{" "}
            <a href={TYPEFORM_LINK} target="_blank" rel="noreferrer">
              here
            </a>
          </>
        ) : (
          "2024 Applications are now closed. Check back in March 2025 for our 2025 Application."
        )}
      </span>
    </ApplicationClosedSectionWrapper>
  );
};

export default ApplicationClosed;
